import React from "react";
import { Link } from "react-router-dom";

const LoggedInLandingPage = () => (
  <div
    style={{
      backgroundColor: "black",
      color: "white",
      height: "100vh",
      width: "100vw",
    }}
  >
    <h1>FGOD</h1>
    <ul style={{ listStyleType: "none" }}>
      <li>About</li>
      <Link to="/bylaws" style={{ color: "white" }}>
        By-Laws
      </Link>
      <li>Members</li>
      <li>
        <Link to="/calls" style={{ color: "white" }}>
          Accountability Calls
        </Link>
      </li>{" "}
      <li>Your mother</li>
    </ul>
  </div>
);

export default LoggedInLandingPage;
