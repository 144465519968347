import React from "react";
import "./BylawsPage.css"; // Reuse the CSS from CallsPage

const BylawsPage = () => {
  const bylaws = [
    "Had to have worked a fifth step and have a sponsor.",
    "Attendance policy commitment to coming to meeting.",
    "Dues are to be paid quarterly within the 90 day period.",
    "Quality business meetings all ideas are welcome only part chair people can vote.",
    "Majority vote is recognized.",
    "All FGOD field trips and events are not requirement highly suggested.",
    "Strongly suggest each member has a sponsee.",
  ];

  return (
    <div className="bylaws-container">
      <h1>Bylaws</h1>
      <ul>
        {bylaws.map((bylaw, index) => (
          <li key={index}>{bylaw}</li>
        ))}
      </ul>
    </div>
  );
};

export default BylawsPage;
