import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/Auth";
import { LoginModal } from "./components/LoginModal";
import LandingPage from "./LandingPage";
import LoggedInLandingPage from "./components/LoggedInLandingPage";
import CallsPage from "./CallsPage"; // import the CallsPage component
import "./App.css";
import { auth } from "./firebase";
import BylawsPage from "./BylawsPage"; // import the BylawsPage component

function App() {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // get the current user from the Auth context
  const [loading, setLoading] = useState(true); // add a loading state
  console.log(currentUser); // log the current user to the console

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (
        user &&
        window.location.pathname !== "/landing" &&
        window.location.pathname !== "/calls" &&
        window.location.pathname !== "/bylaws"
      ) {
        navigate("/landing");
      } else if (!user) {
        navigate("/login");
      }
      setLoading(false); // set loading to false after checking the auth status
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; // render a loading message while checking the auth status
  }

  return (
    <AuthProvider>
      <LoginModal />
      <div className="App">
        <Routes>
          <Route path="/landing" element={<LoggedInLandingPage />} />
          <Route path="/login" element={<LandingPage />} />
          <Route path="/calls" element={<CallsPage />} />
          <Route path="/bylaws" element={<BylawsPage />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
