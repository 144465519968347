import React from "react";
import "./LandingPage.css"; // Import the CSS file for styling

const LandingPage = () => {
  return (
    <div className="landing-container">
      <h1>F.G.O.D.</h1>
    </div>
  );
};

export default LandingPage;
