import React, { useEffect, useState } from "react";
import { firestore } from "./firebase";
import { format, startOfMonth, endOfMonth } from "date-fns";
import "./CallsPage.css"; // Import the CSS file

const CallsPage = () => {
  const [calls, setCalls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const now = new Date();
      const startOfThisMonth = startOfMonth(now);
      const endOfThisMonth = endOfMonth(now);

      const data = await firestore
        .collection("calls")
        .where("monthYear", ">=", startOfThisMonth)
        .where("monthYear", "<=", endOfThisMonth)
        .get();

      setCalls(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchData();
  }, []);

  return (
    <div className="calls-container">
      <h1>Accountability Calls</h1>
      <table>
        <thead>
          <tr>
            <th>Caller</th>
            <th>Callee</th>
            <th>Role</th>
            <th>Period</th>
          </tr>
        </thead>
        <tbody>
          {calls.map((call) => (
            <tr key={call.id}>
              <td>{call.caller}</td>
              <td>{call.callee}</td>
              <td>{call.role}</td>
              <td>{format(call.monthYear.toDate(), "MMMM yyyy")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CallsPage;
