import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDtNPKYShGa1-zA2VQcsGLe5XGvMO0GPxg",
  authDomain: "fgodv1.firebaseapp.com",
  projectId: "fgodv1",
  storageBucket: "fgodv1.appspot.com",
  messagingSenderId: "940942600755",
  appId: "1:940942600755:web:01f1b1718bf15478549146",
  measurementId: "G-2XZFPC8KQR",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export const firestore = app.firestore();
export const analytics = app.analytics();

export const signIn = (email, password) => {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      console.log(user);
      return userCredential;
    })
    .catch((error) => {
      var errorCode = error.code;
      console.log(errorCode);
      var errorMessage = error.message;
      console.log(errorMessage);
      // Handle errors here
      throw error;
    });
};

export const signUp = async (email, password) => {
  await auth.createUserWithEmailAndPassword(email, password);
};

export const signOut = async () => {
  await auth.signOut();
};
